import "../scss/main.scss";
import 'slick-carousel';

$(function(){

    $(document).ready(function(){
        $('body').addClass('ready');
    });

    var adminBar;
    if( $('#wpadminbar') ){ adminBar = $('#wpadminbar').innerHeight() }else{ adminBar = 0; }

    if( $('#wpadminbar').length != 0){
        $('#breadcrumb').css( 'top', $('#masthead').innerHeight() + $('#wpadminbar') );
    }else{
        $('#breadcrumb').css( 'top', $('#masthead').innerHeight() );
    }

    $('#btScrollTop').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    if( $('body.admin-bar').length != 0 ){
        $('.bloc--introduction .intro--cta').on('click', function(){
            $('html, body').animate({scrollTop: $(window).innerHeight() - 32}, "slow");
            return false;
        });
    }else{
        $('.bloc--introduction .intro--cta').on('click', function(){
            $('html, body').animate({scrollTop: $(window).innerHeight()}, "slow");
            return false;
        });
    }

    $('#btNav').on('click', function(){
        $(this).addClass('hide');
        $('.col--logo').addClass('upper');
        $('.col--navigation-mobile').addClass('open');
    });

    $('#btCloseNav').on('click', function(){
        $('#btNav').removeClass('hide');
        $('.col--logo').removeClass('upper');
        $('.col--navigation-mobile').removeClass('open');
    });

    $('.bt-search').on('click', function(){
        $('#search').toggleClass('opensearch');
    });

    $('.intro--navmain-open').on('click', function(){
        $(this).addClass('hide');
        $('.intro--navmain-close').removeClass('hide');
        $('.bloc--introduction #nav-main').slideDown();
    });

    $('.intro--navmain-close').on('click', function(){
        $(this).addClass('hide');
        $('.intro--navmain-open').removeClass('hide');
        $('.bloc--introduction #nav-main').slideUp();
    });

    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    // INIT ALL FUNCTIONS
    resize();
    if( $('.backImg').length != 0) {imgBack(); }
    if( $('.bloc--carrousel').length != 0 ||
    $('.bloc--diaporama').length != 0 ||
    $('.products--categories').length != 0 ||
    $('body.tax-product_cat ul.products').length != 0 ||
    $('body.single-product .related ul.products') ) {
        sliders();
    }
    if( $('.accordeon').length != 0) { accordeon(); }
    if( $('.tabs').length != 0){ onglets(); }
    animationScroll();
    menucustom();
    menuCustomMobile();
    //accordeonNavFooter();
    scrollNav();
    //woocommerce();

    // ACTIONS WINDOW
    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    $(window).on('resize', function(){
        resize();
        //accordeonNavFooter();
    });

    $(document).mousemove(function(e){
        animationMouse(e);
        animationMouse2(e);
        animationMouse3(e);
        animationMouse4(e);
        animationMouse5(e);
        animationMouse6(e);
        animationMouse7(e);
        animationMouse8(e);
        animationMouse9(e);
        animationMouse10(e);
        animationMouse11(e);
        animationMouse12(e);
    });


});

function resize(){
    if($('body.admin-bar').length != 0 && $('body.admin-bar.page-vitrine').length == 0){
        $('#masthead').css('top', $('#wpadminbar').innerHeight());
        if($(window).width() < 587 && $(window).scrollTop() != 0 ){
            $('body.admin-bar #btNav').css('top', ($('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) );
        }else{
            $('body.admin-bar #btNav').css('top', ($('#wpadminbar').innerHeight() + $('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) );
        }
    }

    if($('body.page-vitrine').length == 0){ $('main').css('margin-top', $('#masthead').innerHeight()) };
    $('body.page-vitrine .bloc--introduction').css('margin-bottom', $('#masthead').innerHeight() + 36);


    $('#btNav').removeClass('hide');
    $('.col--logo').removeClass('upper');
    $('.col--navigation-mobile').removeClass('open');
}

function actionsScroll(){
    $('.site-search').removeClass('active');

    var scrolled = $(window).scrollTop();

    // Gestion header avec la barre d'admin
    // Si la barre d'admin existe et le modèle de page est une vitrine 
    if($('body.admin-bar.page-vitrine').length !=0 ){
        // Si la fenêtre est plus grande que 585px
        if( $(window).innerWidth() > 585 ){
            // Si le scroll de la fenêtre dépasse la hauteur d'un écran
            if(scrolled > $(window).innerHeight() - 32 ){
                $('body.page-vitrine').addClass('scrolling'); 
            } else {
                $('body.page-vitrine').removeClass('scrolling');
            }
        }else{
            if(scrolled > 0 ){
                $('body.page-vitrine').addClass('scrolling'); 
            } else {
                $('body.page-vitrine').removeClass('scrolling');
            }
        }
    // Sinon le modèle de page est une vitrine uniquement
    }else if($('body.page-vitrine').length !=0 ){
        if(scrolled > $(window).innerHeight() ){
            $('body.page-vitrine').addClass('scrolling'); 
        } else {
            $('body.page-vitrine').removeClass('scrolling');
        }
    // Sinon
    }else{
        if(scrolled > 0 ){
            $('body').addClass('scrolling'); 
        } else {
            $('body').removeClass('scrolling');
        }
    }
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();		
        }	
    });
}

function sliders(){
    $('.bloc--carrousel').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev"><i class="fa-regular fa-arrow-left-long"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-regular fa-arrow-right-long"></i></span>',
    });

    $('.bloc--diaporama').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true,
        centerPadding: '18px',
    });

    $('.products--categories').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev"><i class="fa-regular fa-arrow-left-long"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-regular fa-arrow-right-long"></i></span>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    });

    $('body.tax-product_cat ul.products').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev"><i class="fa-regular fa-arrow-left-long"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-regular fa-arrow-right-long"></i></span>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    });

    $('body.single-product .related ul.products').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev"><i class="fa-regular fa-arrow-left-long"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-regular fa-arrow-right-long"></i></span>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    });
}

function onglets(){
    var hauteurMax = 0;
    $('.tab-content').each(function(){
        if( $(this).innerHeight() >= hauteurMax ){
            hauteurMax = $(this).innerHeight();
            $('.tab-content').css('min-height', hauteurMax);
        }
    });

    $('.tabs .tab').on('click', function(e){
        e.preventDefault();

        if( !$(this).parent('li').hasClass('active') ){
            var tab = $(this).attr('data');

            /* On suprime la class active de tous les contenus */
            $('.tab-content').removeClass('active');
            $('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            $('.tabs li').removeClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            $(tab).addClass('active');
            $(tab).show();

            /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
            $(this).parent('li').addClass('active');

            return false;
        }
    });
}

function accordeon(){
    $('.bloc--accordeon .accordeon--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".accordeon .accordeon--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".accordeon--content:visible").length != 0) {
            $(this).next(".accordeon--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".accordeon .accordeon--content").slideUp("normal");
            $(".accordeon .accordeon--title").removeClass('open');
            $(this).next(".accordeon--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function menucustom(){
    $('#nav-main a[href*="#"], #nav-main-intro a[href*="#"]').on('click', function(event) {
        // Vérifie que le lien est bien une ancre et qu'il pointe vers un ID valide
        if (this.hash !== "" && $(this.hash).length) {
            event.preventDefault();
            var hash = this.hash;

            var scrollHeight = $(hash).offset().top - $('#masthead').innerHeight() - 50;

            $('html, body').animate({
                scrollTop: scrollHeight
            }, 800);
            return false;
        }
    });
}

function menuCustomMobile(){
    $('#nav-mobile > li.menu-item-has-children').each(function(){
        var parent = $(this);
        var brothers = $(this).siblings('.menu-item-has-children');

        $(this).children("a").on('click', function(event){
            event.preventDefault();
            brothers.removeClass('active').children('ul.sub-menu').slideUp();

            parent.toggleClass('active').children('ul.sub-menu').slideToggle(function(){
                if ($(this).is(":visible")) {
                    $(this).css("display", "flex");
                }   
            });
        });
    });    
}

function accordeonNavFooter(){
    if( $(window).width() < 1010 ){
        $('.nav--accordeon .widget_nav_menu ul.menu').slideUp();
        $('.nav--accordeon .widget_nav_menu .cnt--accordion').slideUp();

        // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
        // et on remplace l'élément span qu'ils contiennent par un lien :
        $('.nav--accordeon .widget_nav_menu .widget-title').on('click', function () {
            // Si le sous-menu était déjà ouvert, on le referme :
            if ($(this).next('div').find('ul.menu:visible').length != 0) {
                $(this).next('div').find('ul.menu').slideUp("normal");
                $(this).parent('.widget_nav_menu').next('section').find('.cnt--accordion').slideUp('normal');
            }
            // Si le sous-menu est caché, on ferme les autres et on l'affiche :
            else {
                $(".nav--accordeon .widget_nav_menu ul.menu").slideUp("normal");
                $(this).next('div').find('ul.menu').slideDown("normal");

                $(".nav--accordeon .widget_nav_menu .cnt--accordion").slideUp("normal");
                $(this).parent('.widget_nav_menu').next('section').find('.cnt--accordion').slideDown('normal');
            }
        });

        $('.nav--accordeon .widget_nav_menu .wp-block-heading').on('click', function () {
            // Si le sous-menu était déjà ouvert, on le referme :
            if ($(this).parent('.widget_nav_menu').next('section').find('.cnt--accordion:visible').length != 0) {
                $(this).next('div').find('ul.menu').slideUp("normal");
                $(this).parent('.widget_nav_menu').next('section').find('.cnt--accordion').slideUp('normal');
            }
            // Si le sous-menu est caché, on ferme les autres et on l'affiche :
            else {
                $(".nav--accordeon .widget_nav_menu ul.menu").slideUp("normal");
                $(this).next('div').find('ul.menu').slideDown("normal");

                $(".nav--accordeon .widget_nav_menu .cnt--accordion").slideUp("normal");
                $(this).parent('.widget_nav_menu').next('section').find('.cnt--accordion').slideDown('normal');
            }
        });
    } else {  
        $('.nav--accordeon .widget_nav_menu ul.menu').slideDown();
        $('.nav--accordeon .widget_nav_menu .cnt--accordion').slideDown();
    }
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger, Observer, ScrollSmoother);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 99%",
                end: "top 90%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if( $('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 99%",
                    end: "top 87%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 99%",
                    end: "top 87%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 36,
                duration: 0.5
            });
        });
    }

    if( $('.wp-block-columns').length != 0) {
        let scrollAnimColumns = gsap.utils.toArray('.wp-block-columns');
        scrollAnimColumns.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 99%",
                    end: "top 87%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 36,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }

    if( $('.bloc--linescrolling img').length != 0) {
        let animLineScroll = gsap.utils.toArray('.bloc--linescrolling img');
        animLineScroll.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 60%",
                    end: "top 40%",
                    markers: false,
                    scrub: true
                },
                y: 0,
                duration: 0.5
            });
        });
    }

    if( $('.butterfly path').length != 0) {
        let animButterflies = gsap.utils.toArray('.butterfly path');
        animButterflies.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 70%",
                    end: "top 30%",
                    markers: false,
                    scrub: true
                },
                fill: "#C6AA76",
                duration: 0.5
            });
        });
    }

    if( $('.animIlluside').length != 0) {
        let animIlluSide = gsap.utils.toArray('.animIlluside');
        animIlluSide.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "bottom 100%",
                    end: "top 0%",
                    markers: false,
                    scrub: true
                },
                y: -300,
            });
        });

        let animIlluSideColors = gsap.utils.toArray('.animIlluside path');
        animIlluSideColors.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "bottom 100%",
                    end: "top 0%",
                    markers: false,
                    scrub: true
                },
                fill: '#1E22AA'
            });
        });
    }

    if( $('.bloc--grappe path').length != 0) {
        let animIlluSideColors = gsap.utils.toArray('.bloc--grappe path');
        animIlluSideColors.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 70%",
                    end: "top 30%",
                    markers: false,
                    scrub: true
                },
                fill: "#C6AA76",
                duration: 0.5
            });
        });
    }

    if( $('.bloc--grappe #raisin1').length != 0) {
        let animIlluSideColors = gsap.utils.toArray('.bloc--grappe #raisin1');
        animIlluSideColors.forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 30%",
                    markers: false,
                    scrub: true
                },
                y: '100vh',
                duration: 3,
                opacity: 0
            });
        });
    }
    if( $('.bloc--grappe #raisin2').length != 0) {
        let animIlluSideColors = gsap.utils.toArray('.bloc--grappe #raisin2');
        animIlluSideColors.forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 60%",
                    markers: false,
                    scrub: true
                },
                y: '100vh',
                duration: 3,
                opacity: 0
            });
        });
    }
    if( $('.bloc--grappe #raisin3').length != 0) {
        let animIlluSideColors = gsap.utils.toArray('.bloc--grappe #raisin3');
        animIlluSideColors.forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 70%",
                    markers: false,
                    scrub: true
                },
                y: '100vh',
                duration: 3,
                opacity: 0
            });
        });
    }
    
}

let butterfly = gsap.utils.toArray(".butterfly");
function animationMouse(e) {
    butterfly.forEach((butterfly, index) => {
        const depth = 20;
        const moveX = ((e.pageX)-(window.innerWidth))/depth;
        const moveY = ((e.pageY)-(window.innerHeight))/depth;
        index ++;

        gsap.to(".butterfly", {
            x: moveX * index,
            y: moveY * index,
        });
    });
}

let butterfly2 = gsap.utils.toArray(".butterfly2");
function animationMouse2(e) {
    butterfly2.forEach((butterfly, index) => {
        const depth = 20;
        const moveX = ((e.pageX)-(window.innerWidth))/depth;
        const moveY = ((e.pageY)-(window.innerHeight))/depth;
        index ++;

        gsap.to(".butterfly2", {
            x: moveX * index,
            y: moveY * index,
        });
    });
}

let butterfly3 = gsap.utils.toArray(".butterfly3");
function animationMouse3(e) {
    butterfly3.forEach((butterfly, index) => {
        const depth = -40;
        const moveX = ((e.pageX)+(window.innerWidth))/depth;
        const moveY = ((e.pageY)+(window.innerHeight))/depth;
        index ++;

        gsap.to(".butterfly3", {
            x: moveX * index,
            y: moveY * index,
        });
    });
}


let typo_s = gsap.utils.toArray(".typo_s");
function animationMouse4(e) {
    typo_s.forEach((typo, index) => {
        const depth = 150;
        const moveX = ((e.pageX)-(window.innerWidth))/depth;
        const moveY = ((e.pageY)-(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_s", {
            x: moveY * index,
            y: moveX * index,
        });
    });
}

let typo_e = gsap.utils.toArray(".typo_e");
function animationMouse5(e) {
    typo_e.forEach((typo, index) => {
        const depth = 120;
        const moveX = ((e.pageX)-(window.innerWidth))/depth;
        const moveY = ((e.pageY)+(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_e", {
            x: moveX * index,
            y: moveY * index,
        });
    });
}

let typo_n = gsap.utils.toArray(".typo_n");
function animationMouse6(e) {
    typo_n.forEach((typo, index) => {
        const depth = -70;
        const moveX = ((e.pageX)+(window.innerWidth))/depth;
        const moveY = ((e.pageY)+(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_n", {
            x: moveY * index,
            y: moveX * index,
        });
    });
}

let typo_r = gsap.utils.toArray(".typo_r");
function animationMouse7(e) {
    typo_r.forEach((typo, index) => {
        const depth = 70;
        const moveX = ((e.pageX)+(window.innerWidth))/depth;
        const moveY = ((e.pageY)-(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_r", {
            x: moveX * index,
            y: moveY * index,
        });
    });
}

let typo_i = gsap.utils.toArray(".typo_i");
function animationMouse8(e) {
    typo_i.forEach((typo, index) => {
        const depth = -80;
        const moveX = ((e.pageX)+(window.innerWidth))/depth;
        const moveY = ((e.pageY)+(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_i", {
            x: moveY * index,
            y: moveX * index,
        });
    });
}

let typo_l = gsap.utils.toArray(".typo_l");
function animationMouse9(e) {
    typo_l.forEach((typo, index) => {
        const depth = -100;
        const moveX = ((e.pageX)-(window.innerWidth))/depth;
        const moveY = ((e.pageY)-(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_l", {
            x: moveX * index,
            y: moveY * index,
        });
    });
}

let typo_o = gsap.utils.toArray(".typo_o");
function animationMouse10(e) {
    typo_o.forEach((typo, index) => {
        const depth = -100;
        const moveX = ((e.pageX)-(window.innerWidth))/depth;
        const moveY = ((e.pageY)-(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_o", {
            x: moveY * index,
            y: moveX * index,
        });
    });
}

let typo_f = gsap.utils.toArray(".typo_f");
function animationMouse11(e) {
    typo_f.forEach((typo, index) => {
        const depth = 200;
        const moveX = ((e.pageX)-(window.innerWidth))/depth;
        const moveY = ((e.pageY)-(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_f", {
            x: moveY * index,
            y: moveX * index,
        });
    });
}

let typo_t = gsap.utils.toArray(".typo_t");
function animationMouse12(e) {
    typo_t.forEach((typo, index) => {
        const depth = -100;
        const moveX = ((e.pageX)-(window.innerWidth))/depth;
        const moveY = ((e.pageY)-(window.innerHeight))/depth;
        index ++;

        gsap.to(".typo_t", {
            x: moveY * index,
            y: moveX * index,
        });
    });
}


function scrollNav(){
    $('.bloc--navinterne a.lien').each(function(){
        $(this).on('click', function(event){
            event.preventDefault();
            var target = $(this).attr('href');
            console.log(target);
            if (target !== '') {
                $('html, body').animate({scrollTop: $(target).offset().top - $('#masthead').innerHeight() - 50 }, 500);
                return false;
            }
        });
    });
}

function woocommerce(){
    var price = $('.product--price-unit .woocommerce-Price-amount bdi').text();
    $('button.single_add_to_cart_button').text('Ajouter au panier - '+price);

    $('input.qty').on('change', function() {
        var priceChange = parseFloat($('.woocommerce-variation-price .woocommerce-Price-amount').text().replace(/[^\d.-]/g, ''));
        var qty = $('input.qty').val();
        console.log(qty);
        console.log(priceChange);

        // Mettre à jour le libellé du bouton Ajouter au panier avec le montant total
        $('button.single_add_to_cart_button').text('Ajouter au panier - '+priceChange);
    });
}